// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
// // normalize CSS across browsers
// import "./src/normalize.css"
// // custom CSS styles
// import "./src/style.css"

// Highlighting for code blocks
// import "prismjs/themes/prism-dark.css"
import "prism-theme-night-owl"

// import "gatsby-prismjs-dracula"
import "./src/assets/css/index.css"
